function Header() {
  return (
    <header className="header-area">
      <div className="navgition navgition-transparent">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="#">
                  <img src="/images/logo.png" alt="Logo" />
                  <h6 className="text-center">
                    <small>Growing IT Skills One Byte At A Time</small>
                  </h6>
                </a>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarOne"
                  aria-controls="navbarOne"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse sub-menu-bar"
                  id="navbarOne"
                >
                  <ul className="navbar-nav m-auto">
                    <li className="nav-item active">
                      <a className="page-scroll" href="#home">
                        HOME
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#about">
                        ABOUT
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#services">
                        SERVICES
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#contact">
                        CONTACT
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="navbar-social d-none d-sm-flex align-items-center">
                  <span></span>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="lni-facebook-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni-twitter-original"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni-instagram-original"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni-linkedin-original"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div
        id="home"
        className="header-hero bg_cover"
        style={{ backgroundImage: 'url("/images/download.png")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="header-content">
                <h3 className="header-title">
                  A disruptive Social Enterprise designed to grow IT skills and
                  provide innovative solutions to progressive organisations.
                </h3>
                <p className="text medium">
                  Teamotech is an innovative technology company created by
                  experienced business professionals to revolutionalise the
                  provision of IT services in South Africa. The company founders
                  appreciate the dearth of data analytics and software
                  development skills among young black people and are determined
                  to make a difference.
                </p>
                <ul className="header-btn">
                  <li>
                    <a className="main-btn btn-one" href="#about">
                      LEARN MORE
                    </a>
                  </li>
                  <li>
                    <a className="main-btn btn-two" href="#services">
                      OUR SERVICES
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-4 d-lg-flex d-none"
              style={{
                backgroundImage: 'url("/images/home-font.png")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "400px",
                backgroundPosition: "0 center",
              }}
            ></div>
          </div>
        </div>
        <div className="header-shape">
          <img src="/images/header-shape.svg" alt="shape" />
        </div>
      </div>
    </header>
  );
}

export default Header;
