function Footer() {
  return (
    <footer id="footer" className="footer-area">
      <div className="footer-widget">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-logo-support d-md-flex align-items-end justify-content-between">
                <div className="footer-logo d-flex align-items-end">
                  <a className="mt-30" href="index.html">
                    <img
                      src="./favicon.png"
                      alt="Logo"
                      style={{ width: "54px" }}
                    />
                  </a>

                  <ul className="social mt-30">
                    <li>
                      <a href="#">
                        <i className="lni-facebook-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni-twitter-original"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni-instagram-original"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni-linkedin-original"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="footer-link">
                <h6 className="footer-title">Company</h6>
                <ul>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#contact">Contact</a>
                  </li>
                  <li>
                    <a href="#">Career</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="footer-link">
                <h6 className="footer-title">Product & Services</h6>
                <ul>
                  <li>
                    <a href="#services">Products</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-5">
              <div className="footer-link">
                <h6 className="footer-title">Legal</h6>
                <ul>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-7">
              {/* <div className="footer-newsletter">
                <h6 className="footer-title">Subscribe Newsletter</h6>
                <div className="newsletter">
                  <form action="#">
                    <input type="text" placeholder="Your Email" />
                    <button type="submit">
                      <i className="lni-angle-double-right"></i>
                    </button>
                  </form>
                </div>
                <p className="text">
                  Subscribe to our weekly newsletter. We don’t send spam.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
