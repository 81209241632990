function Services() {
  return (
    <section id="services" className="services-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title pb-10">
              <h4 className="title">We Crafted For</h4>
              <div className="font-weight-bold text">
                <p className="text">
                  Teamo Tech specialise in IT consulting and we pride ourself
                  with upskilling of young graduates in all cloud technology
                  platforms. We offer placements for project management , Agile
                  specialist , Data and Analytics and AI and Machine Learning
                  skills in all major industries.
                </p>
                <p className="text">
                  We deliver tangible results that are tailored to your
                  environment and are focused on empowering and transforming
                  your organisation in an agile way of working.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-md-6">
                <div className="services-content mt-100 d-sm-flex">
                  <div className="services-icon">
                    <i className="lni-certificate"></i>
                  </div>
                  <div className="services-content media-body">
                    <div className="feature-number">
                      <h1>01</h1>
                    </div>
                    <h4 className="services-title">
                      Teamo Academy (Salesforce and AWS)
                    </h4>
                    <p className="text">
                      Teamo tech takes pride in transformational technologies by
                      offering young graduates technology certification that
                      will enable the organisation to shift into the new
                      technology landscape. The academy's objective is to bridge
                      the gap between the latest software technologies skills by
                      creating youth employment, coaching, and mentoring of
                      graduates while creating future skills development.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="services-content mt-100 d-sm-flex">
                  <div className="services-icon">
                    <i className="lni-bar-chart"></i>
                  </div>
                  <div className="services-content media-body">
                    <div className="feature-number">
                      <h1>02</h1>
                    </div>
                    <h4 className="services-title">Data Migration Skills</h4>
                    <p className="text">
                      Data is at the forefront of future technology requirements
                      and organisations need data management processes and
                      policies to be established which will allow organisations
                      to use it to make key strategic decisions that will allow
                      the company to spearhead the fast-changing market and
                      customer changing needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="services-content mt-100 d-sm-flex">
                  <div className="services-icon">
                    <i className="lni-brush"></i>
                  </div>
                  <div className="services-content media-body">
                    <div className="feature-number">
                      <h1>03</h1>
                    </div>
                    <h4 className="services-title">AI and Machine learning</h4>
                    <p className="text">
                      Artificial intelligence and machine learning skills define
                      the future of software solutions at Teamo tech we pride
                      ourselves on exposing unemployed youth to the Machine
                      learning models and Microsoft Bot frameworks that allow
                      for active communication between Human and AI and bots
                      systems.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="services-content mt-100 d-sm-flex">
                  <div className="services-icon">
                    <i className="lni-briefcase"></i>
                  </div>
                  <div className="services-content media-body">
                    <div className="feature-number">
                      <h1>04</h1>
                    </div>
                    <h4 className="services-title">Project Management</h4>
                    <p className="text">
                      We are structured to deliver traditional and agile
                      projects on budget and on time and help grow your business
                      sustainability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services-image d-lg-flex align-items-center">
        <div className="image">
          <img src="/images/services.png" alt="Services" />
        </div>
        <div className="image-floating image-top d-none d-sm-block">
          <img src="/images/text3.png" alt="Services" />
        </div>
        <div className="image-floating image-bottom d-none d-sm-block">
          <img src="/images/text2.png" alt="Services" />
        </div>
      </div>
    </section>
  );
}

export default Services;
