import axios from "axios";

export const SendFormData = async (data, url) => {
  const response = await axios({
    method: "POST",
    url: url,
    data: data,
  });
  return response.data;
};
