function About() {
  return (
    <section
      id="about"
      className="about-area"
      style={{
        background: "url('/images/home-bg.jpeg') 50% 0 no-repeat",
      }}
    >
      <div className="rotateme">
        <img src="/images/4.png" alt="triangle" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 d-lg-flex align-items-center">
            <div className="image">
              <img src="/images/skill.png" alt="Services" />
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="section-title pb-10">
              <h4 className="title">About Us</h4>
              <div className="font-weight-bold text">
                Analytics and Software skills development, Salesforce training
                and deployment, Project Management, Technology adoption,
                disruptive innovation.
              </div>
              <p className="text">
                We are a diverse, agile, 100% black-owned and managed company
                with a unique business proposition of continually developing
                skills in disadvantaged communities. We source skills from
                previously disadvantaged communities and train our candidates to
                develop disruptive innovative solutions that benefit both our
                clients and the communities they live in.
              </p>
              <p className="text">
                We are equally capable of navigating the complex intricacies of
                SMEs and the demands of big business by formulating disruptive
                innovative frameworks to ensure our clients grow their customer
                base and are leaders in their field. Teamtech Solutions ensures
                our clients offer their customers true value through innovative
                solutions that combine cost, experience, and platform value.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-8 section-title">
            <h4 className="h4">
              <i className="lni-pulse heading">&nbsp;</i>Our Values
            </h4>
            <div className="font-weight-bold text">
              Growing the South African and township economies one byte at a
              time.
            </div>
            <ul>
              <li className="text">
                <strong>Diversity</strong>:&nbsp;We train and hire a diverse
                group of professionals to facilitate growth and offer our
                clients solutions that are a sum of all our experiences.
              </li>
              <li className="text">
                <strong>Accountability</strong>:&nbsp;We promise to be
                accountable and deliver on our promises to ensure your project
                is delivered on time and on budget.
              </li>
              <li className="text">
                <strong>Continuous improvement</strong>:&nbsp;We offer your
                continuous improvement through data analytics and the Teamo way
                of embedding a culture of business improvement
              </li>
              <li className="text">
                <strong>Learning Organisation</strong>:&nbsp;A learning
                organisation is a growing organisation. Through the TeamoTech
                academy, we invest in the training of our personnel and our
                organisation's architecture encourages constant knowledge
                sharing between our professionals and clients
              </li>
            </ul>
          </div>
          <div className="col-lg-4 d-lg-flex d-none align-items-center">
            <div className="image">
              <img src="/images/about.png" alt="Services" />
            </div>
          </div>
          <div className="col-6 section-title mt-4">
            <h4 className="h4">
              <i className="lni-bulb heading">&nbsp;</i>Our Objective
            </h4>
            <div className="text">
              Grow our customer base and township IT skills through
              private-public partnerships while providing world-class services
              at affordable rates
            </div>
          </div>
          <div className="col-6 section-title mt-4">
            <h4 className="h4">
              <i className="lni-heart heading">&nbsp;</i>Our Purpose
            </h4>
            <div className="text">
              We are bridging the digital divide through our innovative training
              programs based in previously disadvantaged communities.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
