function Clients() {
  return (
    <section id="clients" className="clients-area mt-4 pt-20">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              className=""
              style={{
                visibility: "visible",
                animationDelay: "0.2s",
                animationName: "fadeInUp",
              }}
            >
              <div className="section-title pb-10">
                <h4 className="title">We Trusted By</h4>
              </div>
              <div className="d-flex brands-logo pt-10 align-items-center">
                <div className="single-logo">
                  <img src="../images/brands/netfi.png" alt="netfi" />
                </div>
                <div className="single-logo">
                  <img
                    src="../images/brands/talis-holdings-logo.jpeg"
                    alt="talis-holdings"
                  />
                </div>
                <div className="single-logo">
                  <img
                    src="../images/brands/global-strake-security.jpg"
                    alt="global-strake-security"
                  />
                </div>
                <div className="single-logo">
                  <img src="../images/brands/terra-cam.jpg" alt="terra-cam" />
                </div>
                <div className="single-logo">
                  <img
                    src="../images/brands/tpf_logo.png"
                    alt="talis-properties"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;
